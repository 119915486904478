import axios from 'axios';
var OrganizationsService = /** @class */ (function () {
    function OrganizationsService() {
    }
    OrganizationsService.prototype.list = function () {
        return axios.get('/api/ivy-organizations/v1/organizations/', {
            withCredentials: true
        });
    };
    OrganizationsService.prototype.create = function (organization) {
        return axios.post('/api/ivy-organizations/v1/organizations/', organization, {
            withCredentials: true
        });
    };
    OrganizationsService.prototype.set = function (organization) {
        return axios.post('/api/ivy-organizations/v1/context/', {
            organization: organization.id
        }, {
            withCredentials: true
        });
    };
    return OrganizationsService;
}());
export default new OrganizationsService();
